export const FirebaseConfig = {
	"projectId": "letaxi-b336a",
	"appId": "1:176844742879:web:4f83d2d48c054115f5c96a",
	"databaseURL": "https://letaxi-b336a-default-rtdb.europe-west1.firebasedatabase.app",
	"storageBucket": "letaxi-b336a.appspot.com",
	"locationId": "europe-west",
	"apiKey": "AIzaSyC-iiXxRYi7xAGbBhFWQBz79C0SEJExIeg",
	"authDomain": "letaxi-b336a.firebaseapp.com",
	"messagingSenderId": "176844742879",
	"measurementId": "G-9NS5J2H1FJ"
};